import React, { useState, useEffect } from 'react';
import { Input, Form, Dialog } from 'antd-mobile';
import { ExclamationCircleOutline } from 'antd-mobile-icons';

interface PensionProps {
  name: string;
}

const Pension: React.FC<PensionProps> = ({ name }) => {
  // Group 1: 基础养老金
  const [P, setP] = useState<string>('12000'); // 当地在岗职工月平均工资
  const [i, setI] = useState<string>('1'); // 平均缴费指数
  const [n, setN] = useState<string>('30'); // 缴费年限
  const [result1, setResult1] = useState<number>(0);

  // Group 2: 个人账户养老金
  const [balance, setBalance] = useState<string>('100000'); // 个人账户余额
  const [age, setAge] = useState<string>('60'); // 退休年龄
  const [result2, setResult2] = useState<number>(0);

  const [expectedAge, setExpectedAge] = useState<string>('85'); // 预期寿命，默认85岁

  // 计算基础养老金
  useEffect(() => {
    if (P && i && n) {
      const pValue = parseFloat(P);
      const iValue = parseFloat(i);
      const nValue = parseFloat(n);
      
      if (!isNaN(pValue) && !isNaN(iValue) && !isNaN(nValue)) {
        // 基础养老金=(P+P×i)÷2×n×1%
        const pension = (pValue + pValue * iValue) / 2 * nValue * 0.01;
        setResult1(Math.round(pension * 100) / 100); // 保留两位小数
      }
    }
  }, [P, i, n]);

  // 计算个人账户养老金
  useEffect(() => {
    if (balance && age) {
      const balanceValue = parseFloat(balance);
      const ageValue = parseFloat(age);
      
      if (!isNaN(balanceValue) && !isNaN(ageValue)) {
        // 个人账户养老金 = 个人账户储存额 ÷ 计发月数
        const months = getPaymentMonths(ageValue);
        const pension = balanceValue / months;
        setResult2(Math.round(pension * 100) / 100); // 保留两位小数
      }
    }
  }, [balance, age]);

  // 根据退休年龄获取计发月数
  const getPaymentMonths = (age: number): number => {
    // 根据国家规定的计发月数表确定
    if (age <= 40) return 233;
    if (age <= 41) return 230;
    if (age <= 42) return 226;
    if (age <= 43) return 223;
    if (age <= 44) return 220;
    if (age <= 45) return 216;
    if (age <= 46) return 212;
    if (age <= 47) return 208;
    if (age <= 48) return 204;
    if (age <= 49) return 199;
    if (age <= 50) return 195;
    if (age <= 51) return 190;
    if (age <= 52) return 185;
    if (age <= 53) return 180;
    if (age <= 54) return 175;
    if (age <= 55) return 170;
    if (age <= 56) return 164;
    if (age <= 57) return 158;
    if (age <= 58) return 152;
    if (age <= 59) return 145;
    if (age <= 60) return 139;
    if (age <= 61) return 132;
    if (age <= 62) return 125;
    if (age <= 63) return 117;
    if (age <= 64) return 109;
    if (age <= 65) return 101;
    if (age <= 66) return 93;
    if (age <= 67) return 84;
    if (age <= 68) return 75;
    if (age <= 69) return 65;
    return 56; // 70岁及以上
  };

  const showIndexInfo = () => {
    Dialog.alert({
      title: '缴费指数计算说明',
      content: (
        <div style={{ textAlign: 'left' }}>
          <p>平均缴费指数(i)：本人历年缴费指数的平均值</p>
          <p>缴费指数 = 本人缴费工资基数 ÷ 社会平均工资</p>
          <p>比如：社会平均工资为 10000 * 12 元，本人缴费工资基数为 8000 * 12 元，那么缴费指数为 0.8；基数为12000 * 12元，那么缴费指数为 1.2。</p>
        </div>
      ),
      confirmText: '我知道了',
    });
  };

  return (
    <div style={{ padding: '16px', maxWidth: '800px', margin: '0 auto', backgroundColor: '#f5f5f5' }}>
      <h1 style={{ 
        fontSize: '24px', 
        textAlign: 'center', 
        marginBottom: '30px',
        color: '#262626',
        fontWeight: 'bold',
        borderBottom: '1px solid #d9d9d9',
        paddingBottom: '10px'
      }}>养老金评估</h1>
      
      <div style={{
        textAlign: 'center',
        color: '#666',
        fontSize: '14px',
        marginTop: '-20px',
        marginBottom: '30px'
      }}>
        本工具仅用于对未来养老金的粗略评估，实际领取金额以社保部门核定为准
      </div>
      
      {/* Group 1: 基础养老金 */}
      <div style={{ 
        marginBottom: '30px',
        backgroundColor: '#ffffff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
      }}>
        <h2 style={{ 
          fontSize: '18px', 
          marginBottom: '20px',
          color: '#262626',
          display: 'flex',
          alignItems: 'center',
          gap: '8px'
        }}>
          <span style={{ 
            backgroundColor: '#595959', 
            width: '4px', 
            height: '18px',
            display: 'inline-block',
            borderRadius: '2px'
          }}></span>
          基础养老金部分
        </h2>
        <Form layout='vertical'>
          <Form.Item 
            label='预估社平工资(P)' 
            required
            extra='退休时社会平均工资'
          >
            <Input
              placeholder='请输入社平工资'
              value={P}
              onChange={setP}
              type='number'
              clearable
            />
          </Form.Item>
          <Form.Item 
            label={
              <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                平均缴费指数(i)
                <ExclamationCircleOutline style={{ color: '#595959' }} onClick={showIndexInfo} />
              </div>
            }
            required
            extra='缴费比重'
          >
            <Input
              placeholder='请输入平均缴费指数，如：1.2'
              value={i}
              onChange={setI}
              type='number'
              step='0.001'
              clearable
            />
          </Form.Item>
          <Form.Item 
            label='缴费年限(n)' 
            required
            extra='实际缴纳养老保险的年限'
          >
            <Input
              placeholder='请输入缴费年限'
              value={n}
              onChange={setN}
              type='number'
              clearable
            />
          </Form.Item>
        </Form>
        <div style={{ marginTop: '20px', fontSize: '16px' }}>
          <strong>基础养老金 = {result1} 元/月</strong>
          {P && i && n && (
            <div style={{ fontSize: '14px', color: '#666', marginTop: '10px' }}>
              计算公式：(P + P × i) ÷ 2 × n × 1%
              <br />
              = ({P} + {P} × {i}) ÷ 2 × {n} × 1%
              <br />
              = {result1} 元/月
            </div>
          )}
        </div>
      </div>

      {/* Group 2: 个人账户养老金 */}
      <div style={{ 
        marginTop: '30px',
        backgroundColor: '#ffffff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
      }}>
        <h2 style={{ 
          fontSize: '18px', 
          marginBottom: '20px',
          color: '#262626',
          display: 'flex',
          alignItems: 'center',
          gap: '8px'
        }}>
          <span style={{ 
            backgroundColor: '#595959', 
            width: '4px', 
            height: '18px',
            display: 'inline-block',
            borderRadius: '2px'
          }}></span>
          个人账户养老金部分
        </h2>
        <Form layout='vertical'>
          <Form.Item 
            label='个人账户余额' 
            required
            extra='养老保险个人账户中的储存额'
          >
            <Input
              placeholder='请输入个人账户余额'
              value={balance}
              onChange={setBalance}
              type='number'
              clearable
            />
          </Form.Item>
          <Form.Item 
            label='退休年龄' 
            required
            extra='开始领取养老金时的年龄'
          >
            <Input
              placeholder='请输入退休年龄'
              value={age}
              onChange={setAge}
              type='number'
              clearable
            />
          </Form.Item>
        </Form>
        <div style={{ marginTop: '20px', fontSize: '16px' }}>
          <strong>个人账户养老金 = {result2} 元/月</strong>
          {balance && age && (
            <div style={{ fontSize: '14px', color: '#666', marginTop: '10px' }}>
              计算公式：个人账户余额 ÷ 计发月数
              <br />
              计发月数：{age ? getPaymentMonths(parseFloat(age)) : '--'}
              <br />
              = {balance} ÷ {age ? getPaymentMonths(parseFloat(age)) : '--'}
              <br />
              = {result2} 元/月
            </div>
          )}
        </div>
      </div>

      {/* 总养老金 */}
      {(result1 > 0 || result2 > 0) && (
        <div style={{ 
          marginTop: '30px', 
          padding: '16px', 
          backgroundColor: '#ffffff', 
          borderRadius: '8px',
          boxShadow: '0 2px 8px rgba(0,0,0,0.05)'
        }}>
          <div style={{ fontSize: '18px', marginBottom: '16px' }}>
            <strong>每月养老金总额 = {(result1 + result2).toFixed(2)} 元</strong>
            <div style={{ fontSize: '14px', marginTop: '8px', color: '#666' }}>
              = 基础养老金({result1}元) + 个人账户养老金({result2}元)
            </div>
          </div>

          <Form layout='vertical'>
            <Form.Item 
              label='预期寿命' 
              extra='用于估算总领取金额，超过该年龄基础养老金继续发放'
            >
              <Input
                placeholder='请输入预期寿命'
                value={expectedAge}
                onChange={setExpectedAge}
                type='number'
                clearable
              />
            </Form.Item>
          </Form>

          {expectedAge && age && (
            <div style={{ marginTop: '16px' }}>
              <div style={{ fontSize: '16px', color: '#262626' }}>
                <strong>
                  总计可领取：{(
                    // 基础养老金可以一直领
                    result1 * 12 * (parseFloat(expectedAge) - parseFloat(age)) + 
                    // 个人账户养老金只能领到余额用完
                    Math.min(
                      parseFloat(balance), 
                      result2 * 12 * (parseFloat(expectedAge) - parseFloat(age))
                    )
                  ).toFixed(2)} 元
                </strong>
              </div>
              <div style={{ fontSize: '14px', marginTop: '8px', color: '#666' }}>
                计算说明：
                <br />
                1. 基础养老金总额 = 每月基础养老金 × 12个月 × (预期寿命 - 退休年龄)
                <br />
                = {result1.toFixed(2)} × 12 × ({expectedAge} - {age}) = {(result1 * 12 * (parseFloat(expectedAge) - parseFloat(age))).toFixed(2)}元
                <br />
                2. 个人账户养老金总额 = Min(个人账户余额, 每月个人账户养老金 × 12个月 × (预期寿命 - 退休年龄))
                <br />
                = Min({balance}, {result2.toFixed(2)} × 12 × ({expectedAge} - {age}))
                <br />
                = {Math.min(parseFloat(balance), result2 * 12 * (parseFloat(expectedAge) - parseFloat(age))).toFixed(2)}元
                {(() => {
                  const totalNeeded = result2 * 12 * (parseFloat(expectedAge) - parseFloat(age));
                  const remainingBalance = parseFloat(balance) - totalNeeded;
                  if (remainingBalance > 0) {
                    return (
                      <>
                        <br />
                        <br />
                        <span style={{ color: '#52c41a' }}>
                          • 到{expectedAge}岁时，个人账户还剩余：{remainingBalance.toFixed(2)}元
                        </span>
                      </>
                    );
                  } else if (totalNeeded > parseFloat(balance)) {
                    const yearsSupported = parseFloat(balance) / (result2 * 12);
                    const ageWhenEmpty = parseFloat(age) + yearsSupported;
                    return (
                      <>
                        <br />
                        <br />
                        <span style={{ color: '#ff4d4f' }}>
                          • 个人账户余额将在{ageWhenEmpty.toFixed(1)}岁时用完
                          <br />
                          • 此后只能领取基础养老金部分
                        </span>
                      </>
                    );
                  }
                  return null;
                })()}
                <br />
                <br />
                说明：
                <br />
                • 基础养老金可以终身领取
                <br />
                • 个人账户养老金仅限个人账户余额，用完即止
                <br />
                • 此计算基于当地月平均工资(P)保持不变的假设
                <br />
                • 实际上，随着经济发展，当地月平均工资(P)通常会逐年增长，届时基础养老金也会相应增加
                <br />
                • 因此，实际领取的总金额可能会高于此处计算的数值
              </div>
            </div>
          )}
        </div>
      )}

      {/* 免责声明 */}
      <div style={{ 
        marginTop: '30px',
        padding: '12px',
        backgroundColor: '#fff3f3',
        borderRadius: '8px',
        border: '1px solid #ffccc7',
        fontSize: '14px',
        color: '#ff4d4f',
        textAlign: 'center'
      }}>
        ⚠️ 免责声明：本评估页面由AI生成，计算结果仅供参考，不具有任何法律效力。具体养老金政策及计算规则请以当地社保部门官方规定为准。
      </div>
    </div>
  );
};

export default Pension;
